import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import store from '@/vuex/store';
import { menuToRoute } from '@/utils/common.js';
import Cookies from 'js-cookie';
import { message } from 'ant-design-vue';

const routes = [
    {
        name: 'Admin',
        path: '/',
        component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
        children: [...adminRoutes],
        meta: { auth: false },
    },
    {
        name: 'Auth',
        path: '/auth',
        component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
        children: [...authRoutes],
        meta: { auth: true },
    },

];

const router = createRouter({
    history: createWebHistory(
        process.env.NODE_ENV === 'production'
            ? process.env.VUE_APP_SUB_ROUTE
                ? process.env.VUE_APP_SUB_ROUTE
                : process.env.BASE_URL
            : process.env.BASE_URL,
    ),
    linkExactActiveClass: 'active',
    routes,
});

/*async 同步*/
router.beforeEach(async (to, from, next) => {
    // 文档滚动到指定的坐标
    window.scrollTo(0, 0);
    document.title = to.meta.title ? to.meta.title : '管理平台';

    /*是否登录*/
    const is_login = store.state.auth.login;

    /*接口 token 过期 退出 logOut*/
    const is_relogin = Cookies.get('relogin');

    if (is_relogin) {
        Cookies.remove('relogin');

        if (is_relogin == 1) {
            store.dispatch('auth/logOut');

            message.error('登录超时，请重新登录');

            return next({ name: 'login' });
        }
    }

    /*登录页*/
    if (to.meta.auth) {
        if (to.query.order_id && to.query.super_token) {
            return next();
        } else {
            if (is_login) return next();
            if (!is_login) return next();

        }
    }

    /*非 登录页*/
    if (!to.meta.auth) {
        if (!is_login) return next({ name: 'login' });

        if (is_login) {
            /*存在 menu 跳转*/
            if (typeof store.state.auth.menuData == 'object' && store.state.auth.menuData.length > 0) return next();

            /*不存在 menu*/
            /*获取menu*/
            await store.dispatch('auth/getMenu')
            // return
            if (typeof store.state.auth.menuData == 'object' && store.state.auth.menuData.length > 0) {
                /*根据menu 生成路由*/
                const menu_to_route = menuToRoute(store.state.auth.menuData)

                /*添加路由*/
                menu_to_route.map((menu_r) => {
                    router.addRoute('Admin', menu_r);
                })

                return next({ path: to.path, query: to.query, replace: true });

            } else {
                next();
            }
        }
    }
});


export default router;
